import { createStore } from 'vuex'
import axios from 'axios';
import router from '@/router'
import { getField, updateField } from 'vuex-map-fields';
import moment from "moment";

export default createStore({
  state: {
    value: new Date(),
    loading: false,
    tempValue: '',
    storeCode: '', //storeCode
    store: {},
    adultNum: +localStorage.getItem("adultNum")|| 2,
    childNum: 0,
    childseat: 0,
    orderTime: '',
    clickYear: '',
    clickMonth: '',
    clickDay: '',
    clickWeek: '',
    quoto: '',
    share_fb: '',
    pageToken: '',
    TWDDMember: "",
    TWDDFromSource: 0,
    activeMenuTab: 'food',
    isGoNext: Boolean,
    isNext: Boolean,
    boxshow: false,
    isChangeMonth: false,
    businessHour: [],
    canOrderTimes: [],
    searchData: {
      city_id: 1,
      total_people: 2,
      date: moment().format("YYYY-MM-DD")
    }, 
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    STORECODE(state, payload) {
      state.storeCode = payload
    },
    BOXSHOW(state) {
      state.boxshow = !state.boxshow;
    },
    BUSINESSHOUR(state, payload) {
      state.businessHour = payload
    },
    CANORDERTIMES(state, payload) {
      state.canOrderTimes = payload
    },
    LOADING(state, payload) {
      state.loading = payload
    },
    STORE(state, payload) {
      state.store = payload
    },
    TEMPVALUE(state, payload) {
      state.tempValue = payload
    },
    ISCHANGEMONTH(state, payload) {
      state.isChangeMonth = payload
    },
    CHANGESEARCHDATA(state, payload) {
      state.searchData = payload
    },

    CHANGEMONTHTEMP(state, payload) {
      state.businessHour = []
      state.canOrderTimes = []

      if (payload === 1) {
        const date = moment(state.tempValue).add(1, 'months')
        state.tempValue = date._d
      } else {
        const date = moment(state.tempValue).subtract(1, 'months');
        state.tempValue = date._d
      }

      const currentMonth = moment(state.value).format('MM')
      const calendarMonth = moment(state.tempValue).format('MM')

      state.isChangeMonth = true
      // if (currentMonth === calendarMonth) {
      //   state.isChangeMonth = false
      // } else {
      //   state.isChangeMonth = true
      // }

      if (currentMonth === calendarMonth) {
        state.tempValue = state.value
      }

      state.isNext = false
      state.orderTime = ""
      state.clickYear = ""
      state.clickYear = ""
      state.clickMonth = ""
      state.clickDay = ""
      state.clickDay = ""

    },
    VALUE(state, payload) {
      state.value = payload
    },
    ADULT(state, payload) {
      state.adultNum = state.adultNum + payload;
    },
    CHILD(state, payload) {
      state.childNum = state.childNum + payload;
    },
    CHILDSEAT(state, payload) {
      state.childseat = state.childseat + payload;
    },
    ADULTNUM(state, payload) {
      state.adultNum = payload;
    },
    CHILDNUM(state, payload) {
      state.childNum = payload;
    },
    CHILDSEATNUM(state, payload) {
      state.childseat = payload;
    },
    ORDERTIME(state, payload) {
      if (state.orderTime !== "") {
        state.orderTime = payload.replace("\"", "").replace("\"", "");
      }
    },
    CLICKORDERTIME(state, payload) {
      state.orderTime = payload.replace("\"", "").replace("\"", "")
    },

    CLICKYEAR(state, payload) {
      state.clickYear = payload;
    },
    CLICKDAY(state, payload) {
      state.clickDay = payload;
    },
    CLICKMONTH(state, payload) {
      state.clickMonth = payload;
    },
    CLICKWEEK(state, payload) {
      state.clickWeek = payload;
    },
    QUOTO(state, payload) {
      state.quoto = `我發現一間超棒的店家「${state.store.store.name}」！！！分享給你～～～`;
    },
    SHAREFB(state) {
      state.share_fb = `https://www.facebook.com/sharer.php?u=${process.env.VUE_APP_JOYMAP_URL}/store/${state.storeCode}&quote=${state.quoto}&hashtag=%23JoyMap享樂地圖`;
    },
    PAGETOKEN(state, payload) {
      state.pageToken = payload
      // const parts = value.split(`; ${payload}=`);
      // state.pageToken = parts.pop().split(";").shift();
    },
    TWDD(state, payload) {
      state.TWDDMember = payload
    },
    TWDDFromSourceFunc(state, payload) {
      state.TWDDFromSource = payload
    },
    ACTIVEMENU(state, payload) {
      state.activeMenuTab = payload
    },
    ISGONEXT(state, payload) {
      state.isGoNext = payload
    },
    ISNEXT(state, payload) {
      state.isNext = payload
    }

  },
  actions: {
    changeSearchData(context, payload) {
      context.commit('CHANGESEARCHDATA', payload)
    },
    togglebox(context) {
      // this.boxshow = !this.boxshow;
      context.commit('BOXSHOW')
    },
    clickMenu(context, menu) {
      context.commit('ACTIVEMENU', menu)
    },
    getCookie(context) {
      const value = `${document.cookie}`;
      const parts = value.split('token=');
      if (parts.length === 2) {
        // context.commit('PAGETOKEN', value)
        if (parts[1] == '' || parts[1] == null || parts[1] == 'null') {
          // 代表有值是從app來
          context.commit('PAGETOKEN', '123');
        } else {
          context.commit('PAGETOKEN', parts[1]);
        }
      }

      if (context.state.pageToken !== '') {
        context.dispatch('getMember');
      }
    },
    getData(context) {
      const api = `${process.env.VUE_APP_API}/store/${context.state.storeCode}`;
      context.commit('LOADING', true);
      axios.get(api).then((response) => {
        context.commit('STORE', response.data.return);
        context.commit('QUOTO');
        context.commit('SHAREFB');
        context.commit('LOADING', false);
        if (response.data.return.store.images.menus.length === 0) {
          context.commit('ACTIVEMENU', 'wine');
        }
      })
        .catch((error) => {
          router.push("/restaurants");
        });
    },
    getMember(context) {
      const api = `${process.env.VUE_APP_TWDD_URL}`;
      if (context.state.pageToken) {
        axios.get(api, {
          headers: { 'token': context.state.pageToken }
          // headers: { 'token': '$2y$10$Q/b25QJifAI5FPU93v/rUOzKLRn1EJ4H63KOI4XMLykeEEsFLMGQ6' }
        }).then((response) => {
          context.commit('TWDD', response.data.return);
          context.commit('TWDDFromSourceFunc', 1);
        })
          .catch((error) => {
            context.commit('TWDDFromSourceFunc', 1);
          });
      }
    },
    adultNumber(context, num) {
      context.commit('ADULT', num)
      context.dispatch('changeNumber')
    },
    childNumber(context, num) {
      context.commit('CHILD', num)
      context.dispatch('changeNumber')
    },
    changeNumber(context) {
      if (
        context.state.adultNum + context.state.childNum >
        context.state.store.order_settings.single_time_order_total
      ) {
        alert("超過人數限制");
      } else {
        context.state.adultNum = context.state.adultNum;
        context.state.childNum = context.state.childNum;
      }
      if (context.state.childNum < context.state.childseat) {
        context.state.childseat = context.state.childNum;
      }
    },
    childseatQty(context, num) {
      context.commit('CHILDSEAT', num)
    },
    childseatQtyPlus(context) {
      if (context.state.childseat < context.state.childNum) {
        context.commit('CHILDSEAT', 1)
      } else {
        context.state.childseat = context.state.childNum;
      }
    },
    childseatQtyMinus(context) {
      if (context.state.childseat === 0) {
        context.state.childseat = 0;
      } else {
        context.commit('CHILDSEAT', -1)
      }
    },
  },
  modules: {
  }
})
