import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueElUpload from 'vue-el-upload';

import './assets/style/output.css'

import VueGoogleMaps from '@fawmi/vue-google-maps'
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

// import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';


// axios.interceptors.request.use(
//     config => {
//         if (store.state.pageToken) {
//             // let token = localStorage.getItem('token');
//             let token = store.state.pageToken
//             // config.headers.common['Authorization'] = token;
//             // config.defaults.headers.common = {'Authorization': `Bearer ${token}`};
//             config.headers.common['Authorization'] = 'Bearer ' + token;
//             // config.headers.common['header'] = token;
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );
createApp(App)
    .use(store)
    .use(router)
    .use(VueDirectiveImagePreviewer)
    .use(VueClipboard)
    .use(VueElUpload)
    .use(VueAxios, axios)
    // .use(VCalendar, {})
    .use(VueViewer, {
    defaultOptions: {
        "navbar": true, //底部縮略圖
        "title": false, //顯示圖片標題
        "toolbar": false, //底部工具欄
        "tooltip": true, //顯示缩放百分比
        "movable": true, //是否可以移動
        "zoomable": true, //是否可以缩放
        "rotatable": false, //是否可旋轉
        "scalable": false, //是否可翻轉
        "transition": true, //使用 CSS3 過度
        "fullscreen": false, //播放時是否全螢幕
        "keyboard": true, //是否支持鍵盤
    }
}).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCxFzBuXEFK6zL_U-CFpR3xNeaLXOgmCTY',
    },
}).mount('#app')
