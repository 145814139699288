import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/store',
    redirect: '/restaurants'
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    alias: '/',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () => import('../views/Loading.vue')
  },
  {
    path: '/store/:id',
    name: 'Home',

    component: () => import('../views/Home.vue')
  },
  {
    path: '/store/:id/info',
    name: 'Info',
    component: () => import('../views/Info.vue')
  },
  {
    path: '/status/:id',
    name: 'Status',
    component: () => import('../views/Status.vue')
  },
  {
    path: '/order/comment/:orderId',
    name: 'Comment',
    component: () => import('../views/Comment.vue')
  },
  {
    path: '/commentsuccess',
    name: 'CommentSuccess',
    component: () => import('../views/CommentSuccess.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'TermsofService',
    component: () => import('../views/TermsofService.vue')
  }
  //   ]
  // },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes

})


window.router = router

export default router
